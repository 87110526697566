import React, { useEffect, useState } from 'react'
import useFullPageLoader from '../../hooks/useFullPageLoader'
import { generateMonthsWithFilterRange, ShowMonthList } from './components/commons'
import { ConsultantPayoutCurrentMonthCMP, ConsultantPayoutMonthCMP } from './components/Months-Listing';
import { toast } from 'sonner';
import { Button } from '@mui/material';


export default function ConsultantPayout() {

  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const { currentMonth, monthList } = generateMonthsWithFilterRange(15);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth || null);
  const [isVeriedScreen, setIsVeriedScreen] = useState(false);

  useEffect(() => {
    // if (currentMonth.monthName !== selectedMonth.monthName) {
    //   console.log('selectedMonth :: ', selectedMonth)
    // }
  }, [selectedMonth]);

  return (
    <div>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox" style={{ position: 'relative' }}>
          {!isVeriedScreen && <FullScreen verifiedScreen={() => setIsVeriedScreen(true)} />}
          <div style={{ display: 'flex', gap: 12 }}>
            <ShowMonthList currentMonth={currentMonth} monthList={monthList} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
            <div style={{ flex: 1, overflow: 'scroll' }}>
              {(currentMonth.monthName == selectedMonth.monthName) ? <div>
                <ConsultantPayoutCurrentMonthCMP />
              </div> : <div>
                <ConsultantPayoutMonthCMP selectedMonth={selectedMonth} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const FullScreen = ({ verifiedScreen }) => {
  const [accessCode, setAccessCode] = useState('');
  const verifyAccess = () => {
    if (accessCode === 'XaFuGT^PUUh$Pq5P') {
      verifiedScreen();
    } else {
      toast.error('Invalid access code');
    }
  }
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999, backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1>Please verify your access</h1>
      <div style={{ display: 'flex', gap: 12, marginTop: 12 }}>
        <input style={{ padding: '8px 20px', borderRadius: 12, border: '1px solid #eee' }} onChange={(e) => setAccessCode(e.target.value)} type="text" placeholder="Enter your access code" />
        <Button onClick={verifyAccess}>Verified</Button>
      </div>
    </div>
  )
}
