export const gender = [
  {
    id: 101,
    name: "Male",
    value: "male",
  },
  {
    id: 102,
    name: "Female",
    value: "female",
  },

];
export const flag = [
  {
    id: 601,
    name: "Missed Chat/call",
    value: "miss",
  },
  {
    id: 602,
    name: "Flag count",
    value: "flag",
  },

];

export const languages = [
  {
    id: 201,
    name: "English",
    value: "english",
  },
  {
    id: 202,
    name: "Punjabi",
    value: "punjabi",
  },
  {
    id: 204,
    name: "Hindi",
    value: "hindi",
  },
  {
    id: 205,
    name: "Marathi",
    value: "marathi",
  },
  // Add other languages here in the same format
  {
    id: 206,
    name: "Tamil",
    value: "tamil",
  },
  {
    id: 207,
    name: "Telugu",
    value: "telugu",
  },
  {
    id: 208,
    name: "Urdu",
    value: "urdu",
  },
  {
    id: 209,
    name: "Bengali",
    value: "bengali",
  },
  {
    id: 210,
    name: "Gujarati",
    value: "gujarati",
  },
  {
    id: 211,
    name: "Kannada",
    value: "kannada",
  },
  {
    id: 212,
    name: "Sanskrit",
    value: "sanskrit",
  },
  {
    id: 213,
    name: "Oriya",
    value: "oriya",
  },
  {
    id: 214,
    name: "Malayalam",
    value: "malayalam",
  },
  {
    id: 215,
    name: "Assamese",
    value: "assamese",
  },
  {
    id: 216,
    name: "Marwari",
    value: "marwari",
  },
  {
    id: 217,
    name: "Manipuri",
    value: "manipuri",
  },
  {
    id: 218,
    name: "Sindhi",
    value: "sindhi",
  },
  {
    id: 219,
    name: "Kashmiri",
    value: "kashmiri",
  },
  {
    id: 220,
    name: "Bodo",
    value: "bodo",
  },
  {
    id: 221,
    name: "Nepali",
    value: "nepali",
  },
  {
    id: 222,
    name: "Konkani",
    value: "konkani",
  },
  {
    id: 223,
    name: "Maithili",
    value: "maithili",
  },
  {
    id: 224,
    name: "Bhojpuri",
    value: "bhojpuri",
  },
  {
    id: 225,
    name: "Rajasthani",
    value: "rajasthani",
  },
];

export const expertist = [
  {
    id: 301,
    name: "Depression",
    value: "depression",
  },
  {
    id: 302,
    name: "MentaiDisoder",
    value: "mentalDisoder",
  },
  {
    id: 303,
    name: "Headache",
    value: "headache",
  },
  {
    id: 304,
    name: "Survical",
    value: "survical",
  },
];

export const consultantType = [
  {
    id: 401,
    name: "Depression",
    value: "depression",
  },
  {
    id: 402,
    name: "MentaiDisoder",
    value: "mentalDisoder",
  },
  {
    id: 403,
    name: "Headache",
    value: "headache",
  },
  {
    id: 404,
    name: "Survical",
    value: "survical",
  },
];

export const qualification = [
  {
    id: 401,
    name: "Diploma",
    value: "Diploma",
  },
  {
    id: 402,
    name: "10",
    value: "10",
  },
  {
    id: 409,
    name: "12",
    value: "12",
  },
  {
    id: 403,
    name: "Graduate",
    value: "graduate",
  },
  {
    id: 404,
    name: "Post Graduate",
    value: "postGraduate",
  },
  {
    id: 405,
    name: "PhD",
    value: "PhD",
  },
  {
    id: 406,
    name: "Others",
    value: "Others",
  },
];

export const mainSourceOfIncome = [
  {
    id: 301,
    name: "Business Owner",
    value: "Business Owner",
  },
  {
    id: 302,
    name: "Self Employed",
    value: "Self Employed",
  },
  {
    id: 303,
    name: "Private Job",
    value: "Private Job",
  },
  {
    id: 304,
    name: "Government Job",
    value: "Government Job",
  },
  {
    id: 305,
    name: "Student",
    value: "Student",
  },
  {
    id: 306,
    name: "None of the Above",
    value: "None of the Above",
  },
];

export const astrologyPlatforms = [
  {
    id: 404,
    name: "Astrotalk",
    value: "Astrotalk",
  },
  {
    id: 405,
    name: "Astroyogi",
    value: "Astroyogi",
  },
  {
    id: 406,
    name: "Guruji Astro",
    value: "Guruji Astro",
  },
  {
    id: 407,
    name: "Astrosage",
    value: "Astrosage",
  },
  {
    id: 408,
    name: "InstaAstro",
    value: "InstaAstro",
  },
  {
    id: 409,
    name: "Anytime Astro",
    value: "Anytime Astro",
  },
  {
    id: 410,
    name: "Bodhi",
    value: "Bodhi",
  },
  {
    id: 411,
    name: "Divine",
    value: "Divine",
  },
  {
    id: 412,
    name: "Suvich",
    value: "Suvich",
  },
  {
    id: 413,
    name: "Vama",
    value: "Vama",
  },
  {
    id: 414,
    name: "Ganesha Speaks",
    value: "Ganesha Speaks",
  },
  {
    id: 415,
    name: "Other",
    value: "Other",
  },
];

export const experience = [
  { id: 1, name: "1", value: "1" },
  { id: 2, name: "2", value: "2" },
  { id: 3, name: "3", value: "3" },
  { id: 4, name: "4", value: "4" },
  { id: 5, name: "5", value: "5" },
  { id: 6, name: "6", value: "6" },
  { id: 7, name: "7", value: "7" },
  { id: 8, name: "8", value: "8" },
  { id: 9, name: "9", value: "9" },
  { id: 10, name: "10", value: "10" },
  { id: 11, name: "11", value: "11" },
  { id: 12, name: "12", value: "12" },
  { id: 13, name: "13", value: "13" },
  { id: 14, name: "14", value: "14" },
  { id: 15, name: "15", value: "15" },
  { id: 16, name: "16", value: "16" },
  { id: 17, name: "17", value: "17" },
  { id: 18, name: "18", value: "18" },
  { id: 19, name: "19", value: "19" },
  { id: 20, name: "20", value: "20" },
];

export const dailyHoursOptions = [
  { id: 1, name: "1", value: "1" },
  { id: 2, name: "2", value: "2" },
  { id: 3, name: "3", value: "3" },
  { id: 4, name: "4", value: "4" },
  { id: 5, name: "5", value: "5" },
  { id: 6, name: "6", value: "6" },
  { id: 7, name: "7", value: "7" },
  { id: 8, name: "8", value: "8" },
  { id: 9, name: "9", value: "9" },
  { id: 10, name: "10", value: "10" },
  { id: 11, name: "11", value: "11" },
  { id: 12, name: "12", value: "12" },
];

export const hearAboutOptions = [
  { id: 701, name: "Social Media", value: "Social Media" },
  { id: 702, name: "Word of Mouth", value: "Word of Mouth" },
  { id: 703, name: "Internet Search", value: "Internet Search" },
  { id: 704, name: "Advertisement", value: "Advertisement" },
  { id: 705, name: "Friend or Family", value: "Friend or Family" },
  { id: 706, name: "Other", value: "Other" },
];

export const sourceOfIncome = [
  { id: 701, name: "Business owner", value: "Business owner" },
  { id: 702, name: "Self employed", value: "Self employed" },
  { id: 703, name: "Private jobs", value: "Private jobs" },
  { id: 704, name: "Government jobs", value: "Government jobs" },
  { id: 705, name: "Student", value: "student" },
  { id: 706, name: "None of the above", value: "None of the above" },
];
