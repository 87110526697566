import { Link, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { user } from "../../routes/route";
import { IconButton, Paper, useScrollTrigger } from "@mui/material";
import profile from "../../Assests/image/profile_not_found.png";
import CommonListing, {
  CommonListingName,
} from "../../components/commonListing/CommonListing";
import { Navigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect, useState } from "react";
import RemedyTable from "./viewTable/RemedyTable";
import ConsultantHistoryTable from "./viewTable/ConsultantHistoryTable";
import ModalBox from "../../components/modal/ModalBox";
import {
  getSelectedUserDetail,
  getUserHistoryApi,
  updateUserWallet,
} from "../../services/userManagement";
import scrollToUp from "../../utils/scrollToUp";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toastMessage } from "../../utils/toastMessage";
import { dateFormat } from "../../utils/dateFormat";
import UserStatus from "./UserStatus";
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import { fatchCategoryApi } from "../../services/categoryManagement";
import UserHistory from "./UserHistory";
import { CustomTab, CustomTabs } from "../../components/tabs/Tabs";
import RemedyListing from "./RemedyListing";
import UserConsultantListing from "./UserConsultantListing";
import common from "../../routes/common";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import useDebounce from "../../hooks/useDebounce";
import { useCreatePortal } from "../../hooks/useCreatePortal";
import { allowNumber } from "../../utils/regex";
import { Input } from "../../components/Form";
import { useNavigate } from "react-router-dom";
const { USER_MANAGEMENT } = user;

const tabData = [
  {
    id: "REMEDY",
    title: "Remedy",
    eventKey: "REMEDY",
  },
  {
    id: "CONSULTANT",
    title: "Consultant",
    eventKey: "CONSULTANT",
  },
];

const UserDetail = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  // handle all state
  const [userDetail, setUserDetail] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  let type = searchParam.get("type") || "REMEDY";
  const [total, setTotal] = useState(null);
  const [wallet, setWallet] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [key, setKey] = useState("remedy");
  const [blockUserModel, setBlockUserModel] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [walletActionState, openWalletActionMdl, closeWalletActionMdl, WalletActionModal] = useCreatePortal()
  const [walletAmountInput, setWalletAmountInput] = useState(0);

  const [search, setSearch] = useState(
    getLocalStorage("useDetailList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  useEffect(() => {
    setLocalStorage("useDetailList", "search", search);
  }, [search]);

  // handle all hooks
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  // handle all function

  // const blockUserFunction = () => {
  //   setBlockUserModel(true)
  // }

  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  const closeModel = () => {
    setStatusModal(false);
    setStatusValue("");
    setStatusId("");
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  const getSelectedUserDetailHandler = async (userId) => {
    try {
      showLoader();
      const sendData = {
        userId: userId,
      };
      const { data } = await getSelectedUserDetail(sendData);
      if (data?.code === 200) {
        setUserDetail(data?.result?.details[0]);
        setWallet(data?.result?.wallet);
        scrollToUp();
      } else {
        setUserDetail("");
        toastMessage(data?.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Category List
  const fetchCategoryHandler = async () => {
    try {
      setCategoryList([]);
      showLoader();
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        setCategoryList(data?.result?.categoryList || []);
        // setTotal(res?.data?.data?.pagination?.totalItems || 0)
      } else {
        setCategoryList([]);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const userTypeHandler = (type) => {
    searchParam.set("type", type);
    setSearchParam(searchParam);
  };

  // Fetch Consultant order history
  const fetchUserOrderList = async () => {
    try {
      setOrderList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
        userId: userId,
        type: type,
      };
      if (debounceValue) {
        sendData.search = search
      }
      const { data } = await getUserHistoryApi(sendData);
      console.log("data===>",data)
      if (data.code === 200) {
        setOrderList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
      } else {
        setOrderList([]);
        setTotal(0);
        toastMessage(data.message, "error", "orderList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (userId) {
      getSelectedUserDetailHandler(userId);
    }
  }, [userId]);

  useEffect(() => {
    fetchCategoryHandler();
  }, []);

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }

    setSearchParam(searchParam);
    fetchUserOrderList();
  }, [searchParam, debounceValue]);

  // console.log(orderList, "orderList");


  const walletActionsHandler = async () => {
    if (!walletAmountInput || walletAmountInput <= 0) {
      toastMessage('Please enter valid amount!', "error", "wallet_actions");
      return;
    }
    try {
      showLoader();
      const data = {
        userId: userId,
        amount: walletAmountInput,
        action: walletActionState
      };
      let res;
      if (walletActionState == 'wallet_deduct') {
        res = await updateUserWallet(data);
      } else {
        res = await updateUserWallet(data);
      }
      if (res.data.code === 200) {
        toastMessage(res.data.message, "success", walletActionState == 'wallet_deduct' ? "deductionAmount" : "refundedAmount");
        setWalletAmountInput(0);
        closeWalletActionMdl();
      } else {
        toastMessage(res.data.message, "error", walletActionState == 'wallet_deduct' ? "deductionAmount" : 'refundedAmount');
      }
      hideLoader();
    } catch (e) {
      hideLoader();
    }
  };
  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
      navigate(-1);
    } else {
      navigate("/default-route"); 
    }
  };


  return (
    <>
      <WalletActionModal>
        <div style={{ backgroundColor: '#fff', padding: '20px' }}>
          <div className="form-group mb-2">
            <Input
              type="number"
              name="heading"
              value={walletAmountInput || ""}
              required
              onChange={(e) => {
                setWalletAmountInput(e.target.value);
              }}
              placeholder="Amount"
              className={`form-control mb-2`}
              label={walletActionState == 'wallet_deduct' ? "Deduct Amount" : "Refund Amount"}
            // error={!deductionValue && deductionError}
            // maxLength={30}
            />
            <div>
              <button
                className="btn_size"
                onClick={walletActionsHandler}
              >
                Yes
              </button>
              <button
                className="btn_size ms-1"
                onClick={() => closeWalletActionMdl()}
              >
                Discard
              </button>
            </div>
          </div>
        </div>
      </WalletActionModal>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              {/* <Link className="back_icon" to="/user"> */}
                <IconButton onClick={handleBack}>
                  <ArrowBackIosNewIcon className="back_button" />
                </IconButton>
              {/* </Link> */}
              <h4 className="Title">User Management</h4>
            </div>
            <div className="block_user_button">
              <button
                onClick={() => statusUpdateHandler(userId, userDetail.status)}
              >
                {`${userDetail.status ? "Block" : "Unblock"} User`}
              </button>
              <button
                onClick={() => openWalletActionMdl('wallet_deduct')}
              >
                Wallet Deduct
              </button>
              <button
                onClick={() => openWalletActionMdl('wallet_refund')}
              >
                Wallet Refund
              </button>
            </div>
          </div>
          {/* <div className="profile_container"> */}
          <div className="row">
            <div className="col-2">
              <div className="user_image user_icon">
                <figure>
                  <img
                    src={userDetail.profileImage || profile}
                    alt={userDetail.name}
                  />
                </figure>
              </div>
            </div>
            <div className="col-5 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>User Name:</p>
                  <p>{userDetail.name || "---"}</p>
                </li>
                {/* <li>
                  <p>Username:</p>
                  <p>{userDetail.referId || "---"}</p>
                </li> */}
                <li>
                  <p>Email:</p>
                  <p>{userDetail.email || "---"}</p>
                </li>
                <li>
                  <p>Mobile:</p>
                  <p>{userDetail.mobile || "---"}</p>
                </li>
              </ul>
            </div>
            <div className="col-5 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Gender:</p>
                  <p>
                    {userDetail.gender
                      ? CapitalizeFirstWord(userDetail.gender)
                      : "---"}
                  </p>
                </li>
                <li>
                  <p>DOB:</p>
                  <p>
                    {userDetail?.dateOfBirth
                      ? `${dateFormat(userDetail.dateOfBirth)} at ${userDetail.timeOfBirth
                      }`
                      : "---"}
                  </p>
                </li>
                {/* <li>
                  <p>Register Date:</p>
                  <p>
                    {userDetail.createdAt
                      ? dateFormat(userDetail.createdAt)
                      : "---"}
                  </p>
                </li> */}
                <li>
                  <p>Address:</p>
                  <p>
                    {"    "}
                    {userDetail.address || "---"}
                  </p>
                </li>
              </ul>
            </div>

            {/* <div className="col-2">
              <div className="block_user_button">
                <button
                  onClick={() => statusUpdateHandler(userId, userDetail.status)}
                >
                  {`${userDetail.status ? "Block" : "Unblock"} User`}
                </button>
              </div>
            </div> */}
          </div>
          <div className="row cstm_row_overwrite">
            <div className="col-4 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Total Savings :</p>
                  <p>₹ {(Number(wallet?.savedCoins).toFixed(2))}</p>
                </li>
              </ul>
            </div>
            <div className="col-4 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Wallet :</p>
                  <p>₹ {(wallet?.remainingCoins && Number(wallet?.remainingCoins).toFixed(2))||"---"}</p>
                </li>
              </ul>
            </div>
            <div className="col-4 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  {/* <p>Total Spending :</p> */}
                  {/* <p>₹ 500</p> */}
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}

          {userDetail.problems?.length > 0 && (
            <div className="mt-4">
              <h5>Problems</h5>
              <CommonListingName
                problemList={userDetail.problems}
                categoryList={categoryList}
              />
            </div>
          )}
          <div className="tab_table w-500">
            <div className="FilterBox FilterArea ">
              <div className="form-group search_lg_size userSearch">
                {/* <input
                  type="search"
                  className="form-control"
                  placeholder="Search"
                  value={search || ""}
                  style={{ "--color": "#FF8989" }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="user_search_icon">{common.SEARCH_ICON}</span> */}
              </div>
              <CustomTabs
                value={type}
                onChange={(e, section) => {
                  userTypeHandler(section);
                }}
                aria-label="Content"
                variant="fullWidth"
              >
                <CustomTab value="REMEDY" label="Remedy" />
                <CustomTab value="CONSULTANT" label="Consultant" />
              </CustomTabs>
            </div>
          </div>

          {type === "REMEDY" && (
            <RemedyListing
              orderList={orderList}
              total={total}
              handlePageChange={handlePageChange}
              activePage={activePage}
            />
          )}
          {type === "CONSULTANT" && (
            <UserConsultantListing
              orderList={orderList}
              total={total}
              handlePageChange={handlePageChange}
              activePage={activePage}
            />
          )}
        </div>
      </div>
      {statusModal && (
        <UserStatus
          show={statusModal}
          close={closeModel}
          setUserDetail={setUserDetail}
          setListing={""}
          status={statusValue}
          id={statusId}
        />
      )}
    </>
  );
};
export default UserDetail;
