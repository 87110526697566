// addBannerApi
import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fatch-category-listing--------------------------
export const fetchImageListApi = async (data) => {
  const endPoint =
    `/admin/gallery` + "?" + new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// get gallery detail
export const fetchImageDetalListApi = async (data) => {
  const endPoint =
    `/admin/gallery/details` +
    "?" +
    new URLSearchParams({ ...data }).toString();

  return await axios.get(endPoint, setJwtToken());
};

// approve multiple image

export const approveMultipleImageHandler = async (data) => {
  const endPoint = `/admin/gallery/approve`;

  return await axios.put(endPoint, data, setJwtToken());
};

//delete multiple image

// export const deleteMultipleImageHandler = async (data) =>{
//   console.log("data===>" , data);
//   const endpoint = `/admin/gallery/delete` +
//   "?" +
//   new URLSearchParams({ ...data }).toString();

//   return await axios.delete(endpoint , data , setJwtToken())
// }

export const deleteMultipleImageHandler = async (data) => {
  try {
    const query = data.imagesId.map((id) => `id=${id}`).join("&");
    const endpoint = `/admin/gallery/delete?${query}`;
    return await axios.delete(endpoint, setJwtToken());
  } catch (error) {
    // Log the error details
    if (error.response) {
      console.error("Server responded with an error:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      console.error("No response received from server:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }
    throw error;
  }
};

// // ---------------------add-Banner---------------------------
// export const addBannerApi = async (data) => {
//   const endPoint = '/admin/banner/add'
//   return await axios.post(endPoint, data, setMultiPartHeader())
// }

// // ---------------------update-banner---------------------------
// export const updateBannerApi = async (data) => {
//   const endPoint = `/admin/banner/edit`
//   return await axios.put(endPoint, data, setMultiPartHeader())
// }
// // ---------------------change-status-banner---------------------------
// export const changeBannerStatus = async (data) => {
//   const endPoint = `/admin/banner/block_unblock`
//   return await axios.put(endPoint, data, setJwtToken())
// }
