import React, { Fragment, useEffect, useState } from "react";
import ModalBox from "../../components/modal/ModalBox";
import { Input, Select, TextArea } from "../../components/Form";
import useButtonLoader from "../../hooks/UseButtonLoader";
import {
  allowAlphaNumericWithSpace,
  optimizeFunction,
  regex,
} from "../../utils/regex";
import { Avatar } from "@mui/material";
import { charLimit } from "../../utils/charLimit";
import { toastMessage } from "../../utils/toastMessage";
import commonMessages from "../../utils/commonMessages";
import {
  checkImageAspectRatio,
  checkImageDimensions,
  getImageDimension,
} from "../../utils/checkImageDimension";
import { AddExploreApi } from "../../services/exploreManagement";
import VideoPreview from "../../components/videoPreview/VideoPreview";
import VideoThumbnail from "react-video-thumbnail";
import { BaseToImage } from "../../utils/BaseToImage";
import configData from "../../config/configData";
import { convertSecToMin } from "../../utils/convertSecToMin";
const { imageFileRegex, videoFileRegex , audioFileRegex } = regex;

const { IMAGE_FORMAT, MESSAGE_SIZE, VIDEO_FORMAT ,VALID_AUDIO,  AUDIO_EXCEED_SIZE,
  EXCEED_SIZE, ERROR_AUDIO_LOADING
 } = commonMessages;

const AddStories = ({ show, close, listing }) => {
  const [storiesValue, setStoriesValue] = useState({});
  const [imageSize, setImageSize] = useState({
    height: "",
    width: "",
  });
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [image, setImage] = useState([]);
  const [error, setError] = useState({});
  const [buttonref, buttonLoader] = useButtonLoader("Save");
  const [music, setMusic] = useState('')
  const [musicDuration,setMusicDuration]=useState("");

  //----------vaidation------------
  const validation = () => {
    let errorMessage = {};
    let status = true;

    if (!storiesValue.title) {
      errorMessage["title"] = "Please enter title";
      status = false;
    }

    if (!storiesValue.hastag) {
      errorMessage["hastag"] = "Add hashtag ";
      status = false;
    }
    if (!storiesValue.description) {
      errorMessage["description"] = "Add description";
      status = false;
    }
    // if (image.length === 0) {
    //   errorMessage["image"] = "Please add media";
    //   status = false;
    // }

    setError(errorMessage);
    return status;
  };

  // Add Stories media handler
  const imageHandler = async (e) => {
    e.preventDefault();
    const { files } = e.target;
    console.log(files, "FIles");

    if (files.length === 0) {
      clearInputAndImageState(e);
      return;
    }

    const file = files[0];
    const fileName = file.name.toLowerCase();
    const isGif = fileName.endsWith(".gif");
    const isImage = imageFileRegex.test(fileName);
    const isVideo = videoFileRegex.test(fileName);

    if (isGif) {
      handleGif(file, e);
    } else if (isImage) {
      handleImage(file, e);
    } else if (isVideo) {
      setImage([file]);
    } else {
      handleInvalidFileFormat(e);
    }
  };

  const clearInputAndImageState = (e) => {
    e.target.value = "";
    setImage([]);
  };

  const handleGif = async (file, e) => {
    const validAspectRatio = await checkImageAspectRatio(file);
    if (validAspectRatio) {
      setImage([file]);
    } else {
      clearInputAndImageState(e);
    }
  };

  const handleImage = async (file, e) => {
    if (file) {
      setImage([file]);
      const img = await getImageDimension(file);
      setImageSize(img);
    } else {
      clearInputAndImageState(e);
    }
  };

  console.log(imageSize, "ImageSize");

  const handleInvalidFileFormat = (e) => {
    toastMessage(
      "Invalid format: Only GIF and images and videos supported",
      "error",
      "InvalidFormat"
    );
    clearInputAndImageState(e);
  };

  //------------------onChange-input-value---------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === "title") {
      updatedValue = allowAlphaNumericWithSpace(value);
    }
    setStoriesValue({ ...storiesValue, [name]: updatedValue });
  };

  //------------------modal-close--------------------------
  const modalClose = () => {
    setImage("");
    setStoriesValue({ title: "", categoryId: "" });
    setError({});
    close();
  };
 
  //-------------Add Music--------------------------------

  const resetInputAndMusic = (e) => {
    e.target.value = ''
    setMusic('')
  }

  const audioHandler = (e) => {
    e.preventDefault()

    const maxSizeInBytes = configData.audio_legth
    const maxDurationInSeconds = configData.audio_duration
    const file = e.target?.files[0]

    if (!file) {
      resetInputAndMusic(e)
      return
    }

    if (!audioFileRegex.test(file.name)) {
      resetInputAndMusic(e)
      toastMessage(VALID_AUDIO, 'error', 'addAudio')
      return
    }

    if (file.size > maxSizeInBytes) {
      resetInputAndMusic(e)
      toastMessage(EXCEED_SIZE, 'error', 'addAudio')
      return
    }

    const audioElement = document.createElement('audio')
    audioElement.src = URL.createObjectURL(file)
    audioElement.onloadedmetadata = () => {
      if (audioElement.duration > maxDurationInSeconds) {
        resetInputAndMusic(e)
        toastMessage(AUDIO_EXCEED_SIZE, 'error', 'addAudio')
      } else {
        setMusicDuration(convertSecToMin(audioElement.duration));
        setMusic(file)
      }
    }
    audioElement.onerror = () => {
      resetInputAndMusic(e)
      toastMessage(ERROR_AUDIO_LOADING, 'error', 'addAudio')
    }
  }



  //--------------Add-story------------------------
  const addStoriesHandler = async () => {
    const status = validation();
    // console.log(status, "status");
    if (status) {
      try {
        buttonLoader(true);
        const formData = new FormData();
        formData.append("title", storiesValue.title);
        formData.append("hastag", storiesValue.hastag);
        formData.append("description", storiesValue.description);
        if (videoThumbnail) {
          formData.append("videoThumbnail", BaseToImage(videoThumbnail));
        }
        if(imageSize?.height && imageSize?.width){
          formData.append("heigth", imageSize?.height);
          formData.append("width", imageSize?.width);
        }
        if(music){
          formData.append("exploreAudio",music)
        }
        image.forEach((element) => {
          formData.append("exploreMedia", element);
        });
        const { data } = await AddExploreApi(formData);
        if (data.code === 200) {
          toastMessage(data.message, "success", "addStory");
          listing();
          modalClose();
        } else {
          toastMessage(data.message, "error", "addStory");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        buttonLoader(false);
      }
    }
  };

  // console.log(image, "image");

  return (
    <ModalBox
      status={show}
      onClose={modalClose}
      title={"Add Story"}
      closeIcon
      size={"sm"}
      custom
      customizedFooter={
        <div className="model_custom_footer">
          {/* <p>{MESSAGE_SIZE}</p> */}
          <button
            className="btn_size "
            ref={buttonref}
            onClick={addStoriesHandler}
          >
            Save
          </button>
        </div>
      }
    >
      <div>
        <div className="form-group mb-2">
          <Input
            type="text"
            name="title"
            value={storiesValue?.title || ""}
            required
            onChange={(e) => handleChange(e)}
            placeholder=" Title"
            className={`form-control `}
            label={"Title"}
            error={!storiesValue.title && error.title}
            maxLength={30}
          />
        </div>
        <div className="form-group mb-2">
          <Input
            name="hastag"
            type="text"
            placeholder="Hashtag"
            value={storiesValue?.hastag || ""}
            onChange={(e) => handleChange(e)}
            required
            className="form-control"
            label="Hashtag"
            error={!storiesValue.hastag && error.hastag}
          />
        </div>

        <div className="form-group mb-2">
          <TextArea
            name="description"
            type="text"
            placeholder="Write here..."
            value={storiesValue?.description || ""}
            onChange={(e) => handleChange(e)}
            required
            className="form-control"
            label="Description"
            error={!storiesValue.description && error.description}
          />
        </div>
        <div className="form-group my-2">
          <Input
            type="file"
            accept="audio/mp3"
            name="musicMedia"
            required
            onChange={(e) => audioHandler(e)}
            placeholder="Upload Music"
            className={`form-control `}
            label={'Upload Music'}
            error={!music && error.music}
          />
        </div>

        {music && (
          <div className="music-player">
            <audio
              src={music && URL.createObjectURL(music)}
              // autoPlay={true}
              controls
              controlsList="nodownload"
            />
          </div>
        )}

        <div className="form-group my-2">
          <Input
            type="file"
            accept="video/*,image/*"
            name="categoryImage"
            // required
            onChange={(e) => imageHandler(e)}
            placeholder="Media"
            className={`form-control `}
            label={"Media"}
            // error={error.image}
          />
        </div>
        <div>
          {image &&
            image.length > 0 &&
            image.map((img) => {
              return (
                <>
                  {videoFileRegex.test(img?.name) ? (
                    <Fragment key={image[0].lastModified}>
                      <div className="video-container">
                        <VideoPreview videoUrl={URL.createObjectURL(img)} />
                      </div>

                      <div className="video-thumbnail">
                        <VideoThumbnail
                          key={image[0].lastModified}
                          videoUrl={URL.createObjectURL(img)}
                          thumbnailHandler={(thumbnail) =>
                            setVideoThumbnail(thumbnail)
                          }
                          renderThumbnail={false}
                          snapshotAtTime={4}
                          width={1000}
                          height={1000}
                        />
                      </div>
                    </Fragment>
                  ) : (
                    <Avatar
                      variant="square"
                      sx={{
                        width: "100%",
                        borderRadius: 1,
                        height: 140,
                        border: "1px solid #FF8989",
                      }}
                      src={img && URL.createObjectURL(img)}
                    />
                  )}
                </>
              );
            })}
        </div>
      </div>
    </ModalBox>
  );
};

export default AddStories;
