import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { Avatar, IconButton } from "@mui/material";
import Switch from "../../components/Switch/Switch";
import NotFound from "../../components/not-found/NotFound";
import Pagination from "react-js-pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { category, concern, music } from "../../routes/route";
import common from "../../routes/common";
import { toastMessage } from "../../utils/toastMessage";
import findSerialNumber from "../../utils/findSerialNumber";
import { charLimit } from "../../utils/charLimit";
import { fetchMusicApi } from "../../services/musicManagement";
import EditIcon from "@mui/icons-material/Edit";
// import AddMusic from "./AddMusicCategory";
import showFullSizeImage from "../../utils/showFullSizeImage";
// import MusicCategoryStatus from "./MusicCategoryStatus";
// import EditMusicCategory from "./EditMusicCategory";
import scrollToUp from "../../utils/scrollToUp";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import ImagePreviewModel from "../../components/imagePreview/ImagePreviewModel";
import AddIcon from "@mui/icons-material/Add";
import { fetchConcernApi } from "../../services/concern/Index";
import { fetchTransactionApi } from "../../services/transaction";
import { dateFormatWithTime } from "../../utils/dateFormat";
// import AddConcern from "./AddConcern";
// import ConcernStatus from "./ConcernStatus";
// import EditConcern from "./EditConcern";
const { ADD_MUSIC } = category;
const { NOT_FOUND } = common;
const { CONCERN_DETAIL } = concern;

const TransactionManagement = () => {
  // handle all state
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  // const [search, setSearch] = useState(searchParam.get('query') || '')
  const [search, setSearch] = useState(
    getLocalStorage("transaction", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);

  const [addConcernModal, setAddConcernModal] = useState(false);
  const [editConcernModal, setEditConcernModal] = useState(false);
  const [transactionList, setTransactionList] = useState([]);
  const [total, setTotal] = useState(0);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [selectedConcernDetail, setSelectedConcernDetail] = useState(null);
  const [showImagePreviewModel, setShowImagePreviewModel] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [concernTitle, setConcernTitle] = useState("");

  // Function to set localStorage values whenever the state variables change
  useEffect(() => {
    setLocalStorage("transaction", "search", search);
  }, [search]);
  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  // update music category handler
  const updateConcernHandler = (data) => {
    setSelectedConcernDetail(data || null);
    setEditConcernModal(true);
  };

  //*********Handel Block********* */
  // const blockHandler = (id, status) => {
  //     setBlockModal(true);
  //     setBlockId(id);
  //     // setStatusValue(status);
  // };
  //*********Handle update********* */
  // const updateHandler = (id, enName, arName, imageUrl, categoryId) => {
  //   setUpdateModal(true)
  //   setUpdateSubCategoryData({
  //     id,
  //     enName,
  //     arName,
  //     imageUrl,
  //     categoryId,
  //   })
  // }
  // *********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
 


  // close model

  const closeModel = () => {
    setSelectedConcernDetail(null);
    setStatusValue("");
    setStatusId("");
    setAddConcernModal(false);
    setStatusModal(false);
    setEditConcernModal(false);
    setImagePreview("");
    setShowImagePreviewModel(false);
  };

  // //------------------listing-Concern-Api----------------------------------
  const fetchtransactionList = async () => {
    try {
      setTransactionList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
      };
      if (debounceValue) {
        sendData.page = activePage;
        sendData.search = debounceValue;
        // searchParam.delete("page");
        setSearchParam(searchParam);
      }
      const { data } = await fetchTransactionApi(sendData);
      if (data.code === 200) {
        setTransactionList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setTransactionList([]);
        setTotal(0);
        toastMessage(data.message, "error", "transactionList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };



    useEffect(() => {
      if (debounceValue) {
        searchParam.set('query', debounceValue)
      } else {
        searchParam.delete('query')
      }
      if (activePage === 1) {
        searchParam.delete('page')
      }
      setSearchParam(searchParam)
      fetchtransactionList(activePage, debounceValue)
    }, [searchParam, debounceValue])

  useEffect(() => {
    if (activePage === 1) {
      searchParam.delete("page");
    }
    setSearchParam(searchParam);
    fetchtransactionList();
  }, [searchParam, debounceValue]);

  


  

  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <h4 className="Title">Transaction Management</h4>
            <div className="block_user_button">
              {/* <button onClick={() => setAddConcernModal(true)}>
                <AddIcon/>Add Concern
              </button> */}
            </div>
          </div>
          <div className="FilterBox FilterArea ">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">{common.SEARCH_ICON}</span>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>User Name</th>
                  <th>Transaction For</th>
                  <th>Invoice</th>
                  <th>Date & Time</th>
                  <th>User Type</th>
                  <th>Amount</th>
                  <th>Transaction Type</th>
                </tr>
              </thead>

              <tbody>
                {transactionList.length > 0 ? (
                  transactionList.map((transaction, index) => {
                    return (
                      <React.Fragment key={transaction._id || "---"}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10) || "---"}</td>
                          <td>
                            {charLimit(transaction?.userDetails?.name, 30) ||
                              "---"}
                          </td>
                          <td>{(transaction?.transactionFor) || "---"}</td>
                          <td>{(transaction?.invoiceURI)|| "---"}</td>
                          {/* <td>{transaction?.transactionId || "---"}</td> */}
                          <td>{dateFormatWithTime(transaction?.createdAt) || "---"}</td>
                          <td>{(transaction?.userType) || "---"}</td>
                          <td>{Number(transaction?.coins).toFixed(2) || "---"}</td>
                          <td>
                            {(transaction?.transactionType===0?"Credited":"Debited") || "---"}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={6}
                    msg="Transaction not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total >= 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
        {/* {addConcernModal && (
          <AddConcern
            show={addConcernModal}
            close={closeModel}
            listing={fetchtransactionList}
          />
        )}
        {statusModal && (
          <ConcernStatus
            show={statusModal}
            close={closeModel}
            setListing={setTransactionList}
            status={statusValue}
            id={statusId}
          />
        )}
        {editConcernModal && (
          <EditConcern
            show={editConcernModal}
            close={closeModel}
            concernDetail={selectedConcernDetail}
            setConcernDetail={setSelectedConcernDetail}
            listing={fetchtransactionList}
          />
        )} */}
      </div>
    </>
  );
};

export default TransactionManagement;
