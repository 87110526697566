import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import NotFound from "../../components/not-found/NotFound";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "../../components/Button/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";
import Switch from "../../components/Switch/Switch";
import { SecondaryButton } from "../../components/Button/Button";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker, Select } from "../../components/Form";
import { toast } from "sonner";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import useDebounce from "../../hooks/useDebounce";
// import { getOrderApi } from '../../services/orderManagement'
import { SiMicrosoftexcel } from "react-icons/si";
import { IoSearch } from "react-icons/io5";
import profileImage from "../../Assests/image/profile_not_found.png";
import { user } from "../../routes/route";
import { getLocalStorage, setLocalStorage } from "../../config/config";
import {
  getUserListApi,
  logoutUserFromAllDevice,
  sendOtpAPi,
  verifyOtpApi,
} from "../../services/userManagement";
import findSerialNumber from "../../utils/findSerialNumber";

import { toastMessage } from '../../utils/toastMessage'
import { fatchCategoryApi } from '../../services/categoryManagement'
import { charLimit } from '../../utils/charLimit'
import common from '../../routes/common'
import UserStatus from './UserStatus'
import { dateFormat, dateFormatUtc } from '../../utils/dateFormat'
import { gender } from '../../utils/genderList'
import scrollToUp from '../../utils/scrollToUp'
import { CapitalizeFirstWord } from '../../utils/CapitalizeFirstWord'
import Loader from '../../comman/Loader'
import useFileDownloader from '../../hooks/useFileDownloader'
import csv from '../../Assests/image/csv.svg'
import useButtonLoader from '../../hooks/UseButtonLoader'
import ModalBox from '../../components/modal/ModalBox'
import { useOTPModalCNF } from '../../hooks/useOTPModalCNF'

const { USER_DETAIL, USER_DELETE } = user;
const { NOT_FOUND } = common;


// console.log('Gender', gender)

const UserManagement = () => {
  let URL = process.env.REACT_APP_API_URL
  const [searchParam, setSearchParam] = useSearchParams()
  let activePage = +searchParam.get('page') || 1
  const { open: openCNFModal, OTPModalCNF } = useOTPModalCNF();
  const [search, setSearch] = useState(
    getLocalStorage("userList", "search") || ""
  );
  const debounceValue = useDebounce(search || "", 300);
  const [total, setTotal] = useState(0);
  const [userList, setUserList] = useState([]);
  const [problemList, setProblemList] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [statusValue, setStatusValue] = useState("");
  const [statusId, setStatusId] = useState("");
  const [logoutUserModel, setLogoutUserModel] = useState(false);

  const [buttonref, buttonLoader] = useButtonLoader("Logout");

  const [WriteOtpModal, setwriteOtpModal] = useState(false);
  const genderValue = searchParam.get("gender") || "";
  // const [statusSearch, setStatusSearch] = useState('')
  const registrationDate = searchParam.get("registrationDate") || "";
  const problemValue = searchParam.get("problem") || "";
  // const [endDateSearch, setEndDateSearch] = useState('')

  useEffect(() => {
    setLocalStorage("userList", "search", search);
  }, [search]);

  const handleLogoutHandler = async () => {
    try {
      buttonLoader(true);
      const sendData = { userId: statusId };
      const { data } = await logoutUserFromAllDevice(sendData);
      if (data.code === 200) {
        console.log("Date", data);
        logoutCloseModel();
      } else {
        toastMessage(data.message, "error", "userList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  // handle all hooks
  const { loading, downloadFile } = useFileDownloader();

  const [loader, showLoader, hideLoader] = useFullPageLoader();

  const handleExcelDownload = () => {
    try {
      const url = `${URL}/admin/user/download`;
      const params = {};
      downloadFile(url, `user-excel-file-${dateFormat(new Date())}`, params);
    } catch (e) {
      console.log(e.message);
    }
  };
  //*********Handel status********* */
  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  //*********Logout handler function********* */
  const userLogoutHandler = (id) => {
    setStatusId(id);
    setLogoutUserModel(true);
  };

  // gender handler

  const genderHandler = (value) => {
    if (genderValue === value) {
      searchParam.delete("gender");
    } else {
      searchParam.set("gender", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  // Problem handler

  const problemValueHandler = (value) => {
    if (problemValue === value) {
      searchParam.delete("problem");
    } else {
      searchParam.set("problem", value);
    }
    searchParam.delete("page");
    setSearchParam(searchParam);
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };
  //*********Handel refresh icon********* */
  const refreshHandler = (event) => {
    setSearch("");
    searchParam.delete("page");
    searchParam.delete("gender");
    searchParam.delete("problem");
    searchParam.delete("registrationDate");
    setSearchParam(searchParam);
  };
  //------------------usre-listing-Api----------------------------------
  const getUserList = async (debounceValue) => {
    try {
      showLoader();
      const sendData = {
        page: `${activePage}`,
        limit: "10",
        filter: {},
      };
      if (debounceValue) {
        sendData.page = '1'
        sendData.search = debounceValue
        // searchParam.delete('page')
        setSearchParam(searchParam)
      }
      if (problemValue) {
        sendData.filter.problems = problemValue;
      }
      if (genderValue) {
        sendData.filter.gender = genderValue;
      }
      if (registrationDate) {
        sendData.filter.registerDate = registrationDate;
      }
      const { data } = await getUserListApi(sendData);
      if (data.code === 200) {
        setUserList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
        scrollToUp();
      } else {
        setUserList([]);
        setTotal(0);
        toastMessage(data.message, "error", "userList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  const closeModel = () => {
    setStatusModal(false);
    setStatusValue("");
    setStatusId("");
  };

  const logoutCloseModel = () => {
    setLogoutUserModel(false);
    setStatusId("");
  };

  // problem list handler function

  const fetchProblemHandler = async () => {
    try {
      showLoader();
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        setProblemList(data?.result?.categoryList || []);
      } else {
        setProblemList([]);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };
  
  

  const handleRegistrationDate = (date) => {
    const utcDateString = dateFormatUtc(date);
    searchParam.delete("page");
    searchParam.set("registrationDate", utcDateString);
    setSearchParam(searchParam);
  };

  // useEffect(() => {
  //   if (debounceValue) {
  //     searchParam.set('query', debounceValue)
  //   } else {
  //     searchParam.delete('query')
  //   }
  //   if (activePage === 1) {
  //     searchParam.delete('page')
  //   }
  //   setSearchParam(searchParam)
  //   getOrder(activePage, debounceValue)
  // }, [searchParam, debounceValue])

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }
    if (!genderValue) {
      searchParam.delete("gender");
    }
    if (!problemValue) {
      searchParam.delete("problem");
    }
    setSearchParam(searchParam);
    getUserList(debounceValue);
  }, [searchParam, debounceValue]);

  useEffect(() => {
    fetchProblemHandler();
  }, []);
  return (
    <>
      <OTPModalCNF verifiedHandle={() => {
        console.log('OTP Verified Successfully!')
      }} />
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            {/* <div className="user_management_heading"> */}
            <h4 className="Title">User Management</h4>
            <div className="block_user_button">
            <span
                className="user_management_excel"
                onClick={handleExcelDownload}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <div className="csv-image-wrapper">
                    <figure>
                      <img src={csv} alt="csv file download" />
                    </figure>
                  </div>
                )}
              </span>
              <div className="mx-2">
                <Link to={`${USER_DELETE}`}>
                  <div className="block_user_button">
                    <button
                    // onClick={() => setAddConsultantModel(true)}
                    >
                      {/* <AddIcon /> */}
                      Deleted User
                    </button>
                  </div>
                </Link>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="FilterBox FilterArea">
            <div className="form-group search_lg_size userSearch">
              <input
                type="search"
                className={`form-control ${search && "active"}`}
                placeholder="Search"
                value={search || ""}
                style={{ "--color": "#FF8989" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="user_search_icon">
                <IoSearch />
              </span>
            </div>

            <div className="group_filter_value">
              <div className="form-group date_picker">
                <DatePicker
                  className={`form-control ${registrationDate && "active"}`}
                  name="startDate"
                  selected={registrationDate}
                  placeholder="Registration Date"
                  onChange={(date) => handleRegistrationDate(date)}
                  // selectsStart
                  // startDate={startDateSearch}
                  isClearable
                  maxDate={Date.now()}
                />
              </div>

              {/* Gender-Filter */}
              <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${genderValue && "active"}`}
                  value={genderValue || ""}
                  onChange={(e) => genderHandler(e.target.value)}
                >
                  <option value="">Gender</option>
                  {/* <option value="male">Male</option>
                  <option value="female">Female</option> */}
                  {gender &&
                    gender.length > 0 &&
                    gender.map((gender, index) => {
                      return (
                        <option value={gender.value} key={gender.id}>
                          {gender.name}
                        </option>
                      );
                    })}
                </Select>
              </div>
              {/* Problem filter */}
              <div className="form-group select_sm_width">
                <Select
                  className={`form-control ${problemValue && "active"}`}
                  value={problemValue || ""}
                  onChange={(e) => problemValueHandler(e.target.value)}
                >
                  <option value={""} key={1}>
                    Problem
                  </option>
                  {problemList &&
                    problemList.length > 0 &&
                    problemList.map((problem, index) => {
                      return (
                        <option value={problem._id} key={problem._id}>
                          {charLimit(problem.title, 20)}
                        </option>
                      );
                    })}
                </Select>
              </div>
              <div className="form-group refresh_button">
                <SecondaryButton onClick={refreshHandler}>
                  <RotateLeftIcon />
                </SecondaryButton>
              </div>
            </div>
          </div>
          <div className="content-table">
            <table>
              <thead>
                <tr>
                  <th>S. No.</th>
                  {/* <th>User Id</th> */}
                  <th>Profile</th>
                  <th>Name</th>
                  <th>User Id</th>
                  <th>Phone Number</th>
                  {/* <th>Status</th> */}
                  <th>Email</th>
                  <th>Gender</th>
                  <th>Registration Date</th>
                  <th className="text-center"> Action </th>
                  <th>Logout from all Device</th>
                </tr>
              </thead>
              <tbody>
                {userList.length > 0 ? (
                  userList.map((user, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{findSerialNumber(index, activePage, 10)}</td>
                          {/* <td>{user?._id}</td> */}
                          <td className="user_profile_image">
                            <img
                              src={user.profileImage || profileImage}
                              alt={`${user.name} profile image`}
                            />
                          </td>
                          <td>{user?.name || "---"}</td>
                          <td>{user?.referId || "---"}</td>
                          <td>{user.mobile || "---"}</td>

                          <td>{user.email || "---"}</td>
                          <td>
                            {user.gender
                              ? CapitalizeFirstWord(user.gender)
                              : "---"}
                          </td>
                          <td>{dateFormat(user.createdAt) || "---"}</td>
                          <td>
                            <div className="action_button">
                              <Link to={`${USER_DETAIL}/${user._id}`}>
                                <IconButton
                                  aria-label="update"
                                  className="px-2 py-0"
                                >
                                  <VisibilityIcon className="eye_icon" />
                                </IconButton>
                              </Link>

                              <Switch
                                checked={user.status}
                                onChange={() =>
                                  statusUpdateHandler(user._id, user.status)
                                }
                              />
                            </div>
                          </td>
                          <td>
                            <div className="block_user_button">
                              <button
                                className="mx-4"
                                onClick={() => userLogoutHandler(user._id)}
                              // ref={buttonref}
                              >
                                Logout
                              </button>
                            </div>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <NotFound
                    cols={9}
                    msg="User not found!"
                    icon={NOT_FOUND}
                    loader={loader}
                  />
                )}
              </tbody>
            </table>
          </div>
          {total > 10 ? (
            <div className="pagination-wrapper">
              <div className="Pagination">
                <Pagination
                  activePage={activePage}
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  itemsCountPerPage={10}
                  totalItemsCount={total}
                  pageRangeDisplayed={5}
                  onChange={handlePageChange}
                  hideDisabled={true}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {statusModal && (
        <UserStatus
          show={statusModal}
          close={closeModel}
          setListing={setUserList}
          setUserDetail={""}
          status={statusValue}
          id={statusId}
        />
      )}

      {logoutUserModel && (
        <ModalBox
          status={logoutUserModel}
          onClose={logoutCloseModel}
          title="Logout User from all Device"
          description="Do you want to logout the user from all the devices?"
          action={handleLogoutHandler}
          ref={buttonref}
        />
      )}

    </>
  );
};

export default UserManagement;
