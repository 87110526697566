import { Link, useParams, useSearchParams } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { user } from "../../routes/route";
import { IconButton, Paper, useScrollTrigger } from "@mui/material";
import profile from "../../Assests/image/profile_not_found.png";
import CommonListing from "../../components/commonListing/CommonListing";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Fragment, useEffect, useState } from "react";
// import RemedyTable from './viewTable/RemedyTable'
// import ConsultantHistoryTable from './viewTable/ConsultantHistoryTable'
import ModalBox from "../../components/modal/ModalBox";
import { getSelectedUserDetail, getUserHistoryApi } from "../../services/userManagement";
import scrollToUp from "../../utils/scrollToUp";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { toastMessage } from "../../utils/toastMessage";
import { dateFormat } from "../../utils/dateFormat";
// import UserStatus from '../userManagement/UserStatus'
import { CapitalizeFirstWord } from "../../utils/CapitalizeFirstWord";
import consultantPerformance from "../../Assests/image/consultantPerformance.svg";

import {
  fetchWalletAdditionApi,
  fetchWalletDeductionApi,
  getConsultantOrderHistoryApi,
  getConsultantWaitlist,
  getSelectedConsultantDetail,
  goLiveApi,
  markGreenTickApi,
  sendWarningApi,
} from "../../services/consultantManagement";
import Switch from "../../components/Switch/Switch";
import ConsultantHistory from "./ViewConsultantHistory/ConsultantHistory";
import { fatchCategoryApi } from "../../services/categoryManagement";
import { Input, TextArea } from "../../components/Form";
import {
  allowAlphaNumericWithSpace,
  allowNumber,
  optimizeFunction,
} from "../../utils/regex";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { useNavigate } from "react-router-dom";
import WaitListModal from "../../components/modal/WaitListModal";
const { USER_MANAGEMENT } = user;

const tabData = [
  {
    id: 1,
    title: "Remedy",
    eventKey: "remedy",
  },
  {
    id: 2,
    title: "Consultant History",
    eventKey: "consultant",
  },
];

const ConsultantDetail = () => {
  const navigate = useNavigate();
  const { id: consultantId } = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  let activePage = +searchParam.get("page") || 1;
  const [consultantDetail, setConsultantDetail] = useState("");
  const [consultantGoLive, setConsultantGoLive] = useState(null);
  const [consultantEducationDetail, setConsultantEducationDetail] = useState(
    ""
  );
  const [key, setKey] = useState("remedy");
  const [blockUserModel, setBlockUserModel] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [buttonref, buttonLoader] = useButtonLoader("Send");
  const [warningField, setWarningField] = useState({});
  const [error, setError] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [waitList , setWaitList] = useState([]);
  const [total, setTotal] = useState(null);
  const [totalWaitlist , setTotalWaitlist ] = useState(null)
  const [deductionValue, setDeducationValue] = useState("");
  const [deductionModal, setDeductionModal] = useState(false);
  const [deductionError, setDeductionError] = useState("");
  const [AddDeductState, setAddDeductState] = useState(0); // 0: Deduction, 1:Addition
  const [WaitlistModal , setWaitlistModal]  = useState(false)
  const [greenTickMOdal, setGreenTickModal] = useState(false);
  const [consultantGreenTick, setConsultantGreenTick] = useState(null);
  const [goLiveModal, setGoLiveModal] = useState(false);

  const toggleSelectBox = () => {
    setIsOpen(!isOpen);
  };

  const showWarningHandler = () => {
    setWarningModal(true);
    setIsOpen(false);
  };

  const warningValidation = () => {
    let status = true;
    let error = {};
    if (!warningField.heading) {
      error["heading"] = "Warning heading is mandatory";
      status = false;
    }
    if (!warningField.subject) {
      error["subject"] = "Warning subject is mandatory";
      status = false;
    }
    if (!warningField.warning) {
      error["warning"] = "Warning  is mandatory";
      status = false;
    }
    setError(error);
    return status;
  };

  const deductionValidation = () => {
    let status = true;
    if (!deductionValue) {
      setDeductionError("Please enter amount");
      status = false;
    }
    return status;
  };

  // Send warning handler
  const sendWarningHandler = async () => {
    if (warningValidation()) {
      try {
        const data = {
          consultantId: consultantId,
          subject: warningField.subject || "",
          heading: warningField.heading || "",
          warning: warningField.warning || "",
        };
        buttonLoader(true);
        const res = await sendWarningApi(data);
        if (res.data.code === 200) {
          toastMessage(res.data.message, "success", "sendWarning");
          setWarningModal(false);
          setWarningField({});
          setError({});
        } else {
          toastMessage(res.data.message, "error", "sendWarning");
        }
      } catch (e) {
        console.log("Error:", e.message);
      } finally {
        buttonLoader(false);
      }
    }
  };

  // handle all hooks
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  // handle all function

  // const blockUserFunction = () => {
  //   setBlockUserModel(true)
  // }

  const statusUpdateHandler = (id, status) => {
    setStatusModal(true);
    setStatusId(id);
    setStatusValue(status);
  };

  const closeModel = () => {
    setStatusModal(false);
    setStatusValue("");
    setStatusId("");
  };

  const getSelectedConsultantDetailHandler = async (userId) => {
    try {
      showLoader();
      const sendData = {
        consultantId: consultantId,
      };
      const { data } = await getSelectedConsultantDetail(sendData);
      if (data.code === 200) {
        setConsultantDetail(data?.result);
        // setConsultantEducationDetail(data.result.education);
        scrollToUp();
      } else {
        setConsultantDetail("");
        toastMessage(data.message, "error", "consultantDetail");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  // Skill List
  const fetchCategoryHandler = async () => {
    try {
      setSkillList([]);
      showLoader();
      const { data } = await fatchCategoryApi();
      if (data.code === 200) {
        setSkillList(data?.result?.categoryList || []);
        // setTotal(res?.data?.data?.pagination?.totalItems || 0)
      } else {
        setSkillList([]);
        toastMessage(data.message, "error", "categoryList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };

  //*********Handel Pagination********* */
  const handlePageChange = (event) => {
    searchParam.set("page", event);
    setSearchParam(searchParam);
  };

  // Fetch Consultant order history
  const fetchConsultantOrderList = async () => {
    try {
      setOrderList([]);
      showLoader();
      const sendData = {
        page: activePage,
        limit: 10,
        consultantId: consultantId,
      };
      const { data } = await getConsultantOrderHistoryApi(sendData);
      if (data.code === 200) {
        setOrderList(data?.result?.list || []);
        setTotal(data?.result?.total || 0);
      } else {
        setOrderList([]);
        setTotal(0);
        toastMessage(data.message, "error", "orderList");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      hideLoader();
    }
  };
  
  const fetchConsultantWaitlist = async () => {
  try {
    showLoader();
    const sendData = {
        consultantId:consultantId , 
    }
    const { data } = await getConsultantWaitlist(sendData);

    if (data.code === 200) {
      setWaitList(data?.result?.list || []);
      setTotalWaitlist(data?.result?.total || 0);
    } else {
      setWaitList([]);
      setTotalWaitlist(0);
      toastMessage(data.message, "error", "orderList");
    }

} catch (error) {
        console.log("error", error);
}
finally {
  hideLoader();
}
  }

  const walletDeductionHandler = async () => {
    if (deductionValidation()) {
      try {
        const data = {
          consultantId: consultantId,
          amount: deductionValue,
        };
        buttonLoader(true);
        let res;
        if (AddDeductState == 0) {
          res = await fetchWalletDeductionApi(data);
        } else {
          res = await fetchWalletAdditionApi(data);
        }
        if (res.data.code === 200) {
          toastMessage(res.data.message, "success", AddDeductState == 0 ? "deductionAmount" : "refundedAmount");
          setDeductionModal(false);
          setDeducationValue("");
          setDeductionError("");
          setAddDeductState(0);
        } else {
          toastMessage(res.data.message, "error", AddDeductState == 0 ? "deductionAmount" : 'refundedAmount');
        }
      } catch (e) {
        console.log("Error:", e.message);
      } finally {
        buttonLoader(false);
      }
    }
  };

  const greenTickHandler = async () => {
    try {
      buttonLoader(true);
      const res = await markGreenTickApi({
        consultantId: consultantId,
        status: !consultantGreenTick,
      });
      if (res.data.code === 200) {
        toastMessage(res.data.message, "success", "greenTick");
        getSelectedConsultantDetailHandler();
        setGreenTickModal(false);
        setConsultantGreenTick(null);
      } else {
        toastMessage(res?.data?.message, "error", "greenTick");
      }
    } catch (e) {
      console.log("Error:", e.message);
    } finally {
      buttonLoader(false);
    }
  };

  // Go Live Handler
  const goLiveHandler = async () => {
    try {
      buttonLoader(true);
      const res = await goLiveApi({
        consultantId: consultantId,
        status: !consultantGoLive,
      });
      if (res.data.code === 200) {
        toastMessage(res.data.message, "success", "greenTick");
        getSelectedConsultantDetailHandler();
        setGoLiveModal(false);
        setConsultantGoLive(null);
      } else {
        toastMessage(res?.data?.message, "error", "greenTick");
      }
    } catch (e) {
      console.log("Error:", e.message);
    } finally {
      buttonLoader(false);
    }
  };

  useEffect(() => {
    if (consultantId) {
      getSelectedConsultantDetailHandler(consultantId);
    }
  }, [consultantId]);

  useEffect(() => {
    fetchCategoryHandler();
  }, []);

  useEffect(() => {
    if (Number(activePage) === 1) {
      searchParam.delete("page");
    }

    setSearchParam(searchParam);
    fetchConsultantOrderList();
    fetchConsultantWaitlist();
  }, [searchParam]);

  const handleMediaDownload = (url) => {
    // console.log("ok");
    window.open(url);
  };
  const handleBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
      navigate(-1);
    } else {
      navigate("/default-route"); // Fallback route
    }
  };


  return (
    <>
      {loader}
      <div className="WrapperArea">
        <div className="WrapperBox">
          <div className="TitleBox">
            <div className="user_detail_heading">
              {/* <h4 className="Title">User Management</h4> */}
              {/* <Link className="back_icon" to={`/consultant-management/${consultant._id}`}> */}
              <IconButton onClick={handleBack}>
                <ArrowBackIosNewIcon className="back_button" />
              </IconButton>
              {/* </Link> */}
              <h4 className="Title">Consultant Management</h4>
            </div>
            <div className="d-flex">
              {/* <div className="block_user_button me-2">
                <button>Emergency</button>
              </div> */}
              {/* <div className="block_user_button me-2">
                <button>Boost Profile</button>
              </div> */}
              <div className="block_user_button me-2">
                {/* <button onClick={()=>setGreenTickModal(true)}>Mark Green Tick</button> */}
              </div>
              <div className="block_user_button me-2">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    onClick={toggleSelectBox}
                    aria-expanded={isOpen ? "true" : "false"}
                  >
                    Dropdown button
                  </button>
                  <ul
                    className={`dropdown-menu${isOpen ? " show" : ""}`}
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link
                        class="dropdown-item"
                        to="#"
                        onClick={showWarningHandler}
                      >
                        Warning
                      </Link>
                    </li>{" "}
                    <li>
                      <Link
                        class="dropdown-item"
                        to="#"
                        onClick={() => {
                          setDeductionModal(true);
                        }}
                      >
                        Wallet Deduction
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to="#"
                        onClick={() => {
                          setAddDeductState(1)
                          setDeductionModal(true);
                        }}
                      >
                        Wallet Refund
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to="#"
                        onClick={() => {
                          setWaitlistModal(true)
                        }}
                      >
                        Waitlist
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/consultant-management/check-performance/${consultantId}/${consultantDetail?.name}`}
                      >
                        Check Performance
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/form-16A/${consultantId}`}
                      >
                        Upload Form 16
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/shopify/${consultantId}`}
                      >
                        Shopify
                      </Link>
                    </li>
                    {/* <li>
                      <Link class="dropdown-item" to="#">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item" to="#">
                        Something else here
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="profile_container"> */}
          <div className="row">
            <div className="col-2">
              <div className="user_image user_icon">
                <figure>
                  <img
                    src={consultantDetail?.profileImage || profile}
                    alt={consultantDetail?.name}
                  />
                </figure>
              </div>
            </div>
            <div className="col-5 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Name:</p>
                  <p>{consultantDetail?.name || "---"}</p>
                </li>
                <li>
                  <p>User Name:</p>
                  <p>{consultantDetail?.userName || "---"}</p>
                </li>
                <li>
                  <p>Gender:</p>
                  <p>
                    {consultantDetail?.gender
                      ? CapitalizeFirstWord(consultantDetail?.gender)
                      : "---"}
                  </p>
                </li>
                <li>
                  <p>Mobile:</p>
                  <p>{`${consultantDetail?.countryCode || ""}-${consultantDetail?.mobile || "---"
                    }`}</p>
                </li>
                <li>
                  <p>Sec. Mobile:</p>
                  <p>{`${consultantDetail?.secondaryMobileCountryCode || ""}-${consultantDetail?.secondaryMobile || "---"
                    }`}</p>
                </li>
              </ul>
            </div>
            <div className="col-5 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Pan Number:</p>
                  <p>{consultantDetail?.pancadDetails?.panNumber || "---"}</p>
                </li>
                <li>
                  <p>Green Tick</p>
                  <Switch
                    checked={consultantDetail?.greenTick}
                    onChange={() => {
                      setConsultantGreenTick(consultantDetail?.greenTick);
                      setGreenTickModal(true);
                    }}
                  />
                </li>
                <li>
                  <p>Go Live:</p>
                  <Switch
                    checked={consultantDetail.goLive}
                    onChange={() => {
                      // statusUpdateHandler(
                      setConsultantGoLive(consultantDetail?.goLive);
                      setGoLiveModal(true);
                      // )
                    }}
                  />
                </li>
                <li>
                  <p>Address:</p>
                  <p>{consultantDetail?.address || "---"}</p>
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}

          {/* <div>
            <h4>About</h4>
            <p>
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam
              expedita quod maiores eius amet ut sunt assumenda laboriosam
              commodi veniam!
            </p>
          </div> */}

          <div className="row consultant-container">
            <div className="col-4 user_detail_container ">
              <ul className="selected_user_detail">
                <li>
                  <p>Wallet:</p>
                  <p>{consultantDetail?.wallet || "---"}</p>
                </li>
                <li>
                  <p>Consultant Score:</p>
                  <p>{consultantDetail?.score || "---"}</p>
                </li>
                <li>
                  <p>Language:</p>
                  {consultantDetail &&
                    consultantDetail.language &&
                    consultantDetail.language.map((data, index) => (
                      <Fragment key={index}>
                        <p>{data}</p>
                        {index !== consultantDetail?.language.length - 1 && (
                          <p>,</p>
                        )}
                      </Fragment>
                    ))}
                </li>
                <li>
                  <p>Education:</p>
                  <p>
                    {consultantDetail?.highestQualification ||
                      consultantDetail?.qualification}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-4 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Earning:</p>
                  <p>
                    {(consultantDetail?.totalRevenue?.totalRevenue &&
                      Number(
                        consultantDetail?.totalRevenue?.totalRevenue
                      ).toFixed(2)) ||
                      "---"}
                  </p>
                </li>
                <li>
                  <p>Per/Min Rate:</p>
                  <p>{consultantDetail?.price}</p>
                </li>
                <li>
                  <p>Certification:</p>
                  <p>
                    {consultantDetail &&
                      consultantDetail?.certificate.length > 0 && (
                        <img
                          src={consultantPerformance}
                          alt="Consultant performance"
                          onClick={() =>
                            handleMediaDownload(consultantDetail.certificate[0])
                          }
                        />
                      )}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-4 user_detail_container">
              <ul className="selected_user_detail">
                <li>
                  <p>Last Month:</p>
                  <p>{consultantDetail?.lastMonth || "---"}</p>
                </li>
                <li>
                  <p>Total Warning:</p>
                  <p>{consultantDetail?.warningsCounts}</p>
                </li>
                <li>
                  <p>Rating & Review:</p>
                  <p>{consultantDetail?.ratingAverage?.average || "---"}</p>
                </li>
                <li>
                  <p>Address:</p>
                  <p>{consultantDetail?.address || "---"}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            {consultantDetail?.expertise?.length > 0 && (
              <div className="col-6">
                <div className="mt-4">
                  <h5>Expertise</h5>
                  <CommonListing problemList={consultantDetail?.expertise} />
                </div>
              </div>
            )}
          </div>
          <div className="row">
            {consultantDetail?.primarySkills?.length > 0 && (
              <div className="col-6">
                <div className="mt-4">
                  <h5>Primary Skills</h5>
                  <CommonListing
                    problemList={consultantDetail?.primarySkills}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="row mb-2">
            {consultantDetail?.otherSkills?.length > 0 && (
              <div className="col-6">
                <div className="mt-4">
                  <h5>Other Skills</h5>
                  <CommonListing
                    problemList={consultantDetail.otherSkills.filter(
                      (skill) => !consultantDetail.primarySkills.includes(skill)
                    )}
                  />
                </div>
              </div>
            )}
          </div>
          {/* {consultantDetail.skills?.length > 0 && (
              <div className="col-6">
                <div className="mt-4">
                  <h5>Skill</h5>
                  <CommonListing problemList={consultantDetail.skills} categoryList={skillList} />
                </div>
              </div>
            )} */}

          {/* <div className="tab_table">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => {
                setKey(k)
              }}
              className="mb-3 wishlist_activ"
            >
              {tabData &&
                tabData.length > 0 &&
                tabData.map((tab) => (
                  <Tab key={tab.id} eventKey={tab.eventKey} title={tab.title}>
                    <div className="target_wrapper">
                      {key === 'remedy' && <RemedyTable />}
                      {key === 'consultant' && <ConsultantHistoryTable />}
                    </div>
                  </Tab>
                ))}
            </Tabs>
          </div> */}
          <div>
            <h5>Consultant History</h5>
            <div>
              <ConsultantHistory
                handlePageChange={handlePageChange}
                orderList={orderList}
                activePage={activePage}
                total={total}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {statusModal && (
        <UserStatus
          show={statusModal}
          close={closeModel}
          setUserDetail={setUserDetail}
          setListing={''}
          status={statusValue}
          id={statusId}
        />
      )} */}
      <ModalBox
        status={warningModal}
        onClose={() => {
          setWarningModal(false);
          setWarningField({});
          setError({});
        }}
        title={"Warning"}
        closeIcon
        size={"sm"}
        custom
        customizedFooter={
          <div className="model_custom_footer">
            <button
              className="btn_size "
              ref={buttonref}
              onClick={sendWarningHandler}
            >
              Send
            </button>
          </div>
        }
      >
        <div>
          <div className="form-group mb-2">
            <Input
              type="text"
              name="heading"
              value={warningField.heading || ""}
              required
              onChange={(e) => {
                setWarningField({
                  ...warningField,
                  heading: e.target.value,
                });
              }}
              placeholder=" Heading"
              className={`form-control `}
              label={"Heading"}
              error={!warningField.heading && error.heading}
            // maxLength={30}
            />
            <Input
              type="text"
              name="subject"
              value={warningField.subject || ""}
              required
              onChange={(e) => {
                setWarningField({
                  ...warningField,
                  subject: e.target.value,
                });
              }}
              placeholder=" Subject"
              className={`form-control `}
              label={"Subject"}
              error={!warningField.subject && error.subject}
            // maxLength={30}
            />
            <TextArea
              type="text"
              name="title"
              value={warningField.warning || ""}
              required
              onChange={(e) => {
                setWarningField({
                  ...warningField,
                  warning: e.target.value,
                });
              }}
              placeholder="Warning"
              className={`form-control `}
              label={"Warning"}
              error={!warningField.warning && error.warning}
            />
          </div>
        </div>
      </ModalBox>

      {/* Addition & Deduction Wallet */}
      <ModalBox
        status={deductionModal}
        onClose={() => {
          setDeductionModal(false);
          setDeducationValue("");
          setDeductionError("");
        }}
        title={AddDeductState == 1 ? 'Walled Refund' : "Wallet Deduction"}
        closeIcon
        size={"sm"}
        custom
        customizedFooter={
          <div className="model_custom_footer">
            <button
              className="btn_size "
              ref={buttonref}
              onClick={walletDeductionHandler}
            >
              Yes
            </button>
          </div>
        }
      >
        <div>
          <div className="form-group mb-2">
            <Input
              type="text"
              name="heading"
              value={deductionValue || ""}
              required
              onChange={(e) => {
                setDeducationValue(allowNumber(e.target.value));
              }}
              placeholder="Amount"
              className={`form-control `}
              label={"Amount"}
              error={!deductionValue && deductionError}
            // maxLength={30}
            />
          </div>
        </div>
      </ModalBox>

      {/* Waitlist */}

      <WaitListModal
        status={WaitlistModal}
        onClose={() => {
          setWaitlistModal(false)
        }}
        title={"Waitlist"}
        description={waitList}
        closeIcon
        size={"sm"}
        custom
      >
        <div>
          <div className="form-group mb-2">   
            
          </div>
        </div>
      </WaitListModal>

      <ModalBox
        status={greenTickMOdal}
        onClose={() => {
          setGreenTickModal(false);
          setConsultantGreenTick(null);
        }}
        title="Give Consultant Green Tick"
        description={
          consultantGreenTick
            ? "Do you want to remove green tick from this consultant"
            : "Do you want to give consultant green tick?"
        }
        action={greenTickHandler}
        ref={buttonref}
      />

      <ModalBox
        status={goLiveModal}
        onClose={() => {
          setGoLiveModal(false);
          setConsultantGoLive(null);
        }}
        title="Go Live"
        description={
          consultantGreenTick
            ? "Do you want to revoke consultant from go live?"
            : "Do you want to allow consultant go live?"
        }
        action={goLiveHandler}
        ref={buttonref}
      />

    
    </>
  );
};
export default ConsultantDetail;
