import {
  instance as axios,
  setJwtToken,
  setMultiPartHeader,
} from "../../config/axiosInstance";

// ---------------------fatch-category-listing--------------------------
export const fatchCategoryApi = async () => {
  const endPoint = "/master";
  return await axios.get(endPoint, setJwtToken());
};

// Fetchign category listing
export const fetchCategoryListApi = async (data) => {
  const endPoint =
    `/admin/category` + "?" + new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// ---------------------add-category---------------------------
export const AddCategoryApi = async (data) => {
  const endPoint = `/admin/category/add`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};

//----------------------orderhistory----------------------
// export const Chathistory = async (page , limit, consultantId) => {
//   const endPoint = `/admin/consultant/order/history`;
// const params = {
//   page: page,
//   limit: limit,
//   consultantId: consultantId // Send the consultantId dynamically
// };
//   return await axios.get(endPoint,{params},)
// }

//   // Define the endpoint
//   const endPoint = `/admin/consultant/order/history`;

//   // Query parameters
//   const params = {
//     page: page,
//     limit: limit,
//     consultantId: consultantId // Send the consultantId dynamically
//   };

//   try {
//     // Sending GET request with query parameters
//     const response = await axios.get(endPoint, {
//       params: params,  // This will automatically append the query parameters to the URL
//       headers: setJwtToken() // Assuming setJwtToken() adds JWT token to headers
//     });

//     return response; // Return the response data
//   } catch (error) {
//     console.error('Error fetching chat history:', error);
//     throw error; // Handle the error based on your needs
//   }
// };

// ---------------------update-category---------------------------
export const UpdateCategoryApi = async (data) => {
  const endPoint = `/admin/category/update`;
  return await axios.put(endPoint, data, setMultiPartHeader());
};

// ---------------------change-status-category---------------------------
export const changeStatusCategory = async (data) => {
  const endPoint = `/admin/category/block_unblock`;
  return await axios.put(endPoint, data, setJwtToken());
};

// ---------------------Category Media List -----------------------------//
export const fetchCategoryMediaApi = async (data) => {
  const endPoint =
    `/admin/category/media_list` +
    "?" +
    new URLSearchParams({ ...data }).toString();
  return await axios.get(endPoint, setJwtToken());
};

// ---------------------add-media-category---------------------------
export const AddMediaCategoryApi = async (data) => {
  const endPoint = `/admin/category/add_media`;
  return await axios.post(endPoint, data, setMultiPartHeader());
};

// ----------------------Update-media-category----------------------
export const editMediaCategory = async (data) => {
  const endPoint = `/admin/category/update_media`;
  return await axios.put(endPoint, data, setMultiPartHeader());
};

// Change media category status
export const changeMediaCategoryStatus = async (data) => {
  const endPoint = `/admin/category/block_unblock`;
  return await axios.put(endPoint, data, setJwtToken());
};
