import React from "react";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { toastMessage } from "../../utils/toastMessage";
import { changeMeditationStatus } from "../../services/meditationManagement";
import { approveMultipleImageHandler } from "../../services/imageManagement";

const ApproveMultipleImage = ({
  show,
  close,
  imageId,
  // setListing,
  fetchImageDetailListHandler,
  id,
  imageType,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  //--------------change-status-sub-category------------------------
  const approveImageHandler = async () => {
    try {
      buttonLoader(true);
      let sendData = {
        imagesId: imageId,
        consultantId: id,
        galleryType: imageType,
      };
      console.log(sendData)
      const { data } = await approveMultipleImageHandler(sendData);
      if (data.code === 200) {
        // setListing((prevListing) => {
        //   return prevListing.map((image) => {
        //     if (imageId.includes(image._id)) {
        //       return { ...image, isApprove: !image.isApprove }
        //     }
        //     return image
        //   })
        // })
        // setListing((prevListing) => {
        //   return prevListing.filter((image) => !imageId.includes(image._id))
        // })
        // setListing((prevListing) => {
        //   const updatedListing = prevListing.map((image) => {
        //     if (imageId.includes(image._id)) {
        //       return { ...image, isApprove: !image.isApprove };
        //     }
        //     return image;
        //   });

        //   // Instead of filtering out the removed images, directly update the array
        //   return updatedListing.filter((image) => imageId.includes(image._id));
        // });
        fetchImageDetailListHandler(imageType, id);
        toastMessage(data.message, "success", "approveMultipleImage");
        close();
      } else {
        toastMessage(data.message, "error", "approveMultipleImage");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Approve Selected Image"
      description="Do you want to approve all selected image of this user?"
      action={approveImageHandler}
      ref={buttonref}
    />
  );
};

export default ApproveMultipleImage;
