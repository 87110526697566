import React from "react";
import ModalBox from "../../components/modal/ModalBox";
import useButtonLoader from "../../hooks/UseButtonLoader";
import { toastMessage } from "../../utils/toastMessage";
import { changeMeditationStatus } from "../../services/meditationManagement";
import {
  approveMultipleImageHandler,
  deleteMultipleImageHandler,
} from "../../services/imageManagement";

const DeleteMultipleImage = ({
  show,
  close,
  imageId,
  // setListing,
  fetchImageDetailListHandler,
  id,
  imageType,
}) => {
  const [buttonref, buttonLoader] = useButtonLoader("Update");
  //--------------change-status-sub-category------------------------
  const deleteImageHandler = async () => {
    try {
      buttonLoader(true);
      let sendData = {
        imagesId: imageId,
      };
      const response = await deleteMultipleImageHandler(sendData);
      if (response.data.code === 200) {
        fetchImageDetailListHandler(imageType, id);
        toastMessage(response.data.message, "success", "approveMultipleImage");
      } else {
        toastMessage(response.data.message, "error", "deleteMultipleImage");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      buttonLoader(false);
      close();
    }
  };

  return (
    <ModalBox
      status={show}
      onClose={close}
      title="Delete Selected Image"
      description="Do you want to Delete all selected image of this user?"
      action={deleteImageHandler}
      ref={buttonref}
    />
  );
};

export default DeleteMultipleImage;
