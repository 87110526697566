// import { Button } from "components/Button/Button";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "../Button/Button";
import { removeConsultantWaitlist } from "../../services/consultantManagement";
import { PageItem } from "react-bootstrap";
import { toastMessage } from "../../utils/toastMessage";

const removeWaitlistHandler = async (consultantId, userId) => {
  try {
    console.log("i am here ==>")
    const res = await removeConsultantWaitlist({
      consultantId: consultantId,
      userId: userId,
    });
    console.log("the data are ==>",res)
    if (res.data.code === 200) {
      toastMessage(res.data.message, "success");
    } else {
      toastMessage(res?.data?.message, "error");
    }
  } catch (e) {
    console.log("Error:", e.message);
  }
};

const WaitListModal = ({
  status,
  onClose,
  title,
  description,
  action,
  customizedFooter,
  children,
  size,
  custom,
  closeIcon,
  ref,
}) => {
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#000",
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  // avoid close on back
  const onModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  return (
    <>
      <Dialog
        open={status}
        onClose={onModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={size || "sm"}
        className="custom_modal"
      >
        {closeIcon && (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onClose}
            // col
          >
            {title}
          </BootstrapDialogTitle>
        )}

        {!closeIcon && (
          <DialogTitle id="alert-dialog-title" align={"center"}>
            {title}
          </DialogTitle>
        )}
        <DialogContent className="modal-body">
        {description && description.length > 0 ? (
    [...description].reverse().map((item, index) => (
      <div className="row" key={index}>
        <p>Name: {item.firstName}</p>
        <p>Type: {item.typeOfConsult}</p>
        <p>User Id: {item.userId}</p>
        <button
          onClick={() =>
            removeWaitlistHandler(item.consultantId, item.userId)
          }
          className="custom_button2"
        >
          Remove
        </button>
      </div>
    ))
  ) : (
    <p>There is no one in the waitlist</p>
  )}
      
        </DialogContent>
        {/* <DialogActions className="modal-footer">
          {!customizedFooter ? (
            <>
              <Button onClick={onClose}>No</Button>
              <Button onClick={action} ref={ref}>
                Yes
              </Button>
            </>
          ) : (
            <>
              {!custom && (
                <button onClick={onClose} className="custom_button mx-2">
                  No
                </button>
              )}

              {customizedFooter}
            </>
          )}
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default WaitListModal;
